import React, { useMemo, useState, createContext, ComponentProps, FC } from "react";

export const Contexts = createContext(null);

export const ContextProvider = ({children}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [config, setConfig] = useState([]);
    const [apiData, setApiData] = useState([]);
    const [defaultApiList, setDefaultApiList] = useState([]);
    const [defaultTemplates, setDefaultTemplates] = useState([]);
    const [login, setLogin] = useState(undefined);

    const contexts = useMemo(() => {
        return { isLoading, setIsLoading, config, setConfig, apiData, setApiData, defaultApiList, setDefaultApiList, defaultTemplates, setDefaultTemplates, login, setLogin };
    }, [isLoading, setIsLoading, apiData, setApiData, config, setConfig]);

    return <Contexts.Provider value={contexts}>{children}</Contexts.Provider>;
};
