import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { Button, IconButton, TextField } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import TryIcon from '@mui/icons-material/Try';
import LogoutIcon from '@mui/icons-material/Logout';
import Modal from './Modal';
import { useCookies } from 'react-cookie';
import { Edit, EditOff, ContentCopy } from '@mui/icons-material';

export default function ButtonAppBar(props) {
  let name = props.name;
  let pageid = props.pageid;
  const addPage = props.addPage;
  const removePage = props.removePage;
  const setName = props.setName;
  const isEditDisabled = props.isEditDisabled;
  const changeEdit = props.changeEdit;
  const copyToNew = props.copyToNew;
  const isLoginPage = props.isLoginPage;

  const [topBar, setTopBar] = React.useState(name);
  const textBoxRef = React.useRef("");
  const [showModal, setShowModal] = React.useState(false);

  const [cookies, setCookie, removeCookie] = useCookies(['login']);
  const changeName = () => {
    setTopBar(<div style={{ padding: "10px" }}>
      <TextField defaultValue={name} inputRef={textBoxRef} label="Name" variant="outlined" fullWidth />
      <Button onClick={() => {
        let textBox = textBoxRef.current.value;
        setName(textBox);
        setTopBar(textBox);
        setShowModal(false);
      }
      }>
        Save
      </Button>
    </div>
    );
    setShowModal(true);
  }

  return (
    <>
      <Box className="mb-16" sx={{ flexGrow: 1 }}>
        <AppBar className="fixed flex">
          <Toolbar className="flex w-full space-x-2 bg-slate-900">
            <button className="flex flex-grow" onDoubleClick={changeName}>
              <h1 className="text-2xl font-sans font-bold tracking-tight">
                {name ? name : "Dashboard"}
              </h1>
            </button>
            <button
              className="p-1 w-10 h-10 bg-slate-800 hover:bg-slate-200 text-slate-700 hover:text-slate-700 rounded-full transition-colors duration-200"
              aria-label="isEditDisabled"
              onClick={() => { changeEdit(); }}
            >
              {isEditDisabled ? <EditOff fontSize="medium" /> : <Edit fontSize="medium" />}
            </button>
            {isLoginPage ? "" : <button
              className="p-1 w-10 h-10 bg-slate-800 hover:bg-slate-200 text-slate-700 hover:text-slate-700 rounded-full transition-colors duration-200"
              aria-label="isEditDisabled"
              onClick={() => { copyToNew(); }}
            >
              <ContentCopy fontSize="medium" />
            </button>}
            <button
              className="p-1 w-10 h-10 bg-slate-800 hover:bg-slate-200 text-slate-700 hover:text-slate-700 rounded-full transition-colors duration-200"
              aria-label="remove"
              onClick={() => { removePage(pageid); }}
            >
              <RemoveIcon fontSize="medium" />
            </button>
            <button
              className="p-1 w-10 h-10 bg-slate-800 hover:bg-slate-200 text-slate-700 hover:text-slate-700 rounded-full transition-colors duration-200"
              aria-label="remove"
              onClick={() => { addPage(pageid); }}
            >
              <AddIcon fontSize="medium" />
            </button>
            {cookies.username && <button
              className="p-1 w-10 h-10 bg-slate-800 hover:bg-slate-200 text-slate-700 hover:text-red-500 rounded-full transition-colors duration-200"
              aria-label="logout"
              onClick={() => { removeCookie("username"); removeCookie("password"); window.location.reload(); }}
            >
              <LogoutIcon ontSize="medium" />
            </button>}
          </Toolbar>
        </AppBar>
      </Box>
      <Modal open={showModal} setOpen={setShowModal} jsx={topBar} additionalStyle={{ height: "", width: "", maxWidth: "30%" }} />
    </>
  );
}