import './App.css';
import React from 'react';
import '/node_modules/react-grid-layout/css/styles.css';
import '/node_modules/react-resizable/css/styles.css';
import Dashboard from './pages/Dashboard';
import Box from '@mui/material/Box';
import { Pagination } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { getFromLS, saveToLS, saveConfig, saveToLSById } from './utils';
import sampleConfig from './config.json';
import loginConfig from './loginConfig.json';
import uuid from 'react-uuid';
import demoLayout from './layout.json';
import { useConfig, useLogin } from './hooks';
import GetAllDB from './components/GetAllDB';
import { apiPath } from './utils';
import axios from 'axios';
import Modal from './components/Modal';

const theme = createTheme({
  typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
});



function App() {
  const [page, setPage] = React.useState(1);
  const [dashboardConfig, setDashboardConfig] = React.useState(undefined);

  const [ modalContent, setModalContent ] = React.useState("");
  const [ open, setOpen ] = React.useState(false);

  const [config, setConfig] = useConfig();
  const [login, setLogin] = useLogin();
  console.log("CONFIG", config);

  const createCopy = (config, message) => {
    setOpen(true);
    const callbackFunction = (dashboard, page) => {
      console.log("callbackFunction", dashboard, page);
      let newConfig = { name: "Copy of "+ config.name, cards: config.cards, _id: dashboard._id };
      console.log("newConfig", newConfig);
      updateConfig(page, newConfig);
      setOpen(false);
    }

    // open modal asking to confirm to create copy
    let modalPrompt = (
      <>
      <h1>{message}</h1>
      <button onClick={() => {addPage(callbackFunction);}}>Yes</button>
      <button onClick={() => {setOpen(false);}}>No</button>
      </>
    )

    setModalContent(modalPrompt);
  }

  const updateConfig = (page, newConfig) => {
    console.log("updateConfig", page, newConfig);
    axios.put(apiPath + "/dashboards/" + newConfig._id, { name: newConfig.name, cards: newConfig.cards }).then((res) => {
      console.log("Update Dashboard", res);
      if (res.status === 200) {
        console.log("Update Dashboard success");
        let updatedConfig = [...config];
        updatedConfig[page] = res.data.data;
        console.log("updatedConfig", updatedConfig);
        setConfig(updatedConfig);
      } else {
        console.log("Update Dashboard failed");
      }
    }).catch((err) => {
      console.log("Update Dashboard error", err);
    });
  }

  const fetchConfig = (id, page) => {
    console.log("fetchConfig", page);
    axios.get(apiPath + "/dashboards/" + id).then((res) => {
      console.log("Get Dashboard", res);
      if (res.status === 200) {
        console.log("Get Dashboard success");
        let newConfig = [...config];
        newConfig[page] = res.data.data;
        console.log("newConfig", newConfig);
        setConfig(newConfig);
      } else {
        console.log("Get Dashboard failed");
      }
    }).catch((err) => {
      console.log("Get Dashboard error", err);
    });
  }

  React.useEffect(() => {
    console.log("PAGE", page, config);
    if (config?.length > 0) {
      console.log("change", page, config[page - 1]);

      setDashboardConfig(config[page - 1]);
    }
  }, [page, config]);

  if (config === undefined || dashboardConfig === undefined) {
    return <div><GetAllDB />Loading Dashboard...</div>;
  }

  const addPage = (callbackFunction) => {
    let newPage = Object.keys(config).length + 1;

    axios.post(apiPath + "/dashboards", { name: "New Dashboard " + newPage, ownerid: login }).then((res) => {
      console.log("Create Dashboard", res);
      if (res.status === 201) {
        console.log("Add Dashboard success");
        let newConfig = [...config, res.data.data];
        let createdDashboard = res.data.data;
        axios.post(apiPath + "/users/" + login + "/adddashboard", { dashboardid: res.data.data._id }).then((res) => {
          console.log("Add Dashboard to User", res);
          if (res.status === 200) {
            console.log("Add Dashboard to User success");
            setConfig(newConfig);
            setPage(newPage);
            if(callbackFunction !== undefined){
              callbackFunction(createdDashboard, newPage);
            }
          } else {
            console.log("Add Dashboard to User failed");
          }
        }).catch((err) => {
          console.log("Add Dashboard to User error", err);
        });
      } else {
        console.log("Add Dashboard failed");
      }
    }).catch((err) => {
      console.log("Add Dashboard error", err);
    });
  }

  const removePage = (id) => {
    const configToRemove = config.find((config) => config._id === id);
    console.log("Remove Dashboard", configToRemove, id);
    axios.post(apiPath + "/users/" + login + "/removedashboard", { dashboardid: configToRemove._id }).then((res) => {
      console.log("Remove Dashboard from User", res);
      if (res.status === 200) {
        console.log("Remove Dashboard from User success");
        let newConfig = config.filter((config) => config._id !== id);
        setConfig(newConfig);
        setPage((page) => page - 1);
      } else {
        console.log("Remove Dashboard from User failed");
      }
    }).catch((err) => {
      console.log("Remove Dashboard from User error", err);
    });
  }

  return (
    <div className="relative flex flex-col w-screen h-full min-h-screen bg-slate-900 overflow-scroll pb-14">
      <ThemeProvider theme={theme}>
        <GetAllDB />
        <Dashboard config={dashboardConfig} page={page} updateConfig={updateConfig} addPage={addPage} removePage={removePage} fetchConfig={fetchConfig} createCopy={createCopy} />
        <div className="fixed flex bottom-0 w-full justify-center">
          <Box className="flex mb-4">
            <Pagination className="bg-slate-800 hover:bg-slate-200 rounded-full transition-colors duration-200 opacity-75 hover:opacity-100 backdrop-blur-xl" count={config ? config.length : 0} showFirstButton showLastButton onChange={(e, number) => {
              //console.log("SAVE change", number); 
              setPage(number);
            }} page={page} />
          </Box>
        </div>
      </ThemeProvider>
      <Modal jsx={modalContent} open={open} setOpen={setOpen} />
    </div>
  );
}

export default App;
