import { SpeedDial, SpeedDialAction } from "@mui/material";
import { Menu } from '@mui/icons-material';

export const GridSpeedDial = (props) => {
    let actions = props.actions;
    return (<SpeedDial
            ariaLabel="Dashboard SpeedDial"
            sx={{ position: 'absolute', bottom: 16, right: 16 }}
            icon={<Menu/>}
            >
    {actions.map((action) => (
        <SpeedDialAction
        key={action.name}
        icon={action.icon}
        tooltipTitle={action.name}
        onClick={action.onClick}
        />
    ))}
    </SpeedDial>);
};

export default GridSpeedDial;