import axios from 'axios';

export const apiPath = "https://dashboard.aeros.one/api/";
export function runAPI (apiObj, apiConfig) {
    console.log("running api!");
    let { url, method, headers, body, userInput } = apiObj;

    if(userInput) {
        userInput.forEach((input)=>{
            if(input.in === 'query') {
                url = url.replace(`{${input.var}}`, apiConfig.userInput[input.var]);
            }else if(input.in === 'body') {
                body[input.var] = apiConfig.userInput[input.var];
            }
        });
    }

    switch (method) {
        case 'GET':
            return axios.get(url);
        case 'POST':
            return axios.post(url, body);
        case 'PUT':
            return axios.put(url, body);
        case 'DELETE':
            return axios.delete(url);
        default:
            return axios.get(url);
    }
}

export const logIn = (username, password) => {
    return axios.post(apiPath + '/users/login', {
        username,
        password
    }).then((res) => {
        console.log("login res", res);
        console.log("login id", res.data.data["_id"]);
        return res.status;
    }).catch((err) => {
        console.log("login err", err);
    });
}


//https://stackoverflow.com/questions/5484673/javascript-how-to-dynamically-create-nested-objects-using-object-names-given-by
const createNestedObject = ( base, names, value ) => {
    // If a value is given, remove the last name and keep it for later:
    var lastName = value ? names.pop() : false;

    // Walk the hierarchy, creating new objects where needed.
    // If the lastName was removed, then the last object is not set yet:
    for( var i = 0; i < names.length; i++ ) {
        base = base[ names[i] ] = base[ names[i] ] || {};
    }

    // If a value was given, set it to the last name:
    if( lastName ) base = base[ lastName ] = value;

    // Return the last object in the hierarchy:
    return base;
};

export function parseAPI (apiresult, fields){
    let data = {};
    fields.forEach((field)=>{
        let pathArr = field.path.split('/');
        let curRes = apiresult;
        for(let i=0; i<pathArr.length; i++){
            curRes = curRes[pathArr[i]];
            if (curRes === undefined) {
                curRes = "error 42";
                break;
            }
        }
        data[field.var] = curRes;
    });
    return data;
}

export function saveConfig(num, config, newConfig){
    let tempConfig = [...getFromLS("config", "config")];
    //console.log("SAVING CONFIG", tempConfig, newConfig);
    tempConfig[num] = newConfig;
    saveToLS('config', 'config', tempConfig);
    return tempConfig;
}

export function getFromLS(id, key) {
    let ls = {};
    if (global.localStorage) {
      try {
        ls = JSON.parse(global.localStorage.getItem(id)) || {};
      } catch (e) {
        /*Ignore*/
      }
    }
    //return ls[key];
    return undefined;
  }
  
  export function saveToLS(id, key, value) {
    if (global.localStorage) {
        // let ls = JSON.parse(global.localStorage.getItem(id)) || {};
        // global.localStorage.setItem(
        //     id,
        //     JSON.stringify({...ls,
        //     [key]: value
        //     })
        // );
    }
  }

  export function saveToLSById(id, value) {
    // if (global.localStorage) {
    //     global.localStorage.setItem(
    //         id,
    //         value
    //     );
    // }
  }

export const newApiObj = (uuid, count) => { 
    return {
        "name": "API "+count,
        "apiId": uuid,
        "templateId": "templateId", 
        "userInput": {},
        "field": "",
        "var": "",
        "interval": -1
    }
}