
export default function GlanceCard(props) {
    const colors = {
        "red"       : "bg-gradient-to-r from-red-500 to-red-400",
        "orange"    : "bg-gradient-to-r from-orange-500 to-amber-500",
        "yellow"    : "bg-gradient-to-r from-amber-500 to-yellow-400",
        "green"     : "bg-gradient-to-r from-emerald-500 to-green-400",
        "blue"      : "bg-gradient-to-r from-blue-500 to-blue-400",
        "indigo"    : "bg-gradient-to-r from-indigo-500 to-indigo-400",
        "violet"    : "bg-gradient-to-r from-violet-500 to-violet-400",
        "pink"      : "bg-gradient-to-r from-pink-500 to-rose-400",
        "default"   : "bg-slate-800"
      }
      let color = "bg-slate-800"
    
      if (colors[props.color] !== undefined) {
        color = colors[props.color]
      }
      else {
        color = colors["blue"]
      }
    
      return (
        <div className={`flex flex-row flex-1 items-center w-full h-full p-4 rounded-xl shadow-sm ${color}`}>
          <div className="flex flex-col w-full items-start">
            <h1 className="text-sm text-gray-50 font-bold uppercase">
              {props.title}
            </h1>
            <span className="text-2xl text-gray-50 font-bold">
              {props.value}
            </span>
          </div>
        </div>
      )
}