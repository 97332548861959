export const addCard = (count) => {
    let multifield = `<><Mui.Grid container columns={{ xs: 1, sm: 2, md: 3, lg: 4 }}>`;
    for (let i = 0; i < count; i++) {
        // multifield += `<Mui.Typography {...card${i}.props}>
        //     {card${i}.input}
        // </Mui.Typography>`;
        multifield += `<Mui.Grid item xs={1} sm={1} md={1} key={card${i}.id} >
        <Mui.Paper style={{margin:"5%"}} onClick={onTabClick}>
            <Mui.CardMedia
                component="img"
                height="150"
                image={card${i}.img}
                alt={card${i}.name}
            />
            <Mui.CardContent>
                <Mui.Typography variant="h5">
                    {card${i}.name}
                </Mui.Typography>
                <Mui.Typography variant="body2" style={{height:"20%"}}>
                {card${i}.description}
                </Mui.Typography>
            </Mui.CardContent>
        </Mui.Paper>
      </Mui.Grid>`;
    }
    multifield += `</Mui.Grid></>`;
    return multifield;
};

export const getAddCardData = (count) => {
    let data = {};
    for (let i = 0; i < count; i++) {
        data["card"+i] = {
            id: i,
            img: "",
            description: "",
            name: "AddCard " + i,
            onClick: () => {console.log("Test");}
        };
    }
    return data;
}

export const availableCards = {
        "card0": {
            id: 0,
            img: "/images/Demo.png",
            description: "Card Demo from Material UI",
            name: "Demo",
        },
        "card1": {
            id: 1,
            img: "/images/Webpage.png",
            description: "Embed a Webpage with Iframe",
            name: "Webpage",
        },
        "card2": {
            id: 2,
            img: "/images/Single.png",
            description: "A simple card for Single title field",
            name: "Single",
        },
        "card3": {
            id: 3,
            img: "/images/Multifield.png",
            description: "A simple card with multiple fields",
            name: "Multifield",
        },
        "card4": {
            id: 4,
            img: "/images/Paragraph.png",
            description: "A simple card for Paragraph field",
            name: "Paragraph",
        },
    };

export default addCard;