import * as Curve from "@visx/curve";
import { GradientPinkRed, LinearGradient } from "@visx/gradient";
import { cityTemperature } from "@visx/mock-data";
import { ParentSize } from "@visx/responsive";
import { TextProps as SVGTextProps } from "@visx/text";
import {
  AnimatedAxis, // any of these can be non-animated equivalents
  AnimatedGrid,
  AnimatedAreaSeries,
  buildChartTheme,
  XYChart,
  Tooltip,
} from "@visx/xychart";
import { forEach } from "lodash";

export default function VisxXYGraphCard(props) {
  //   function generateData(length, min, max) {
  //     let returnArray = [];
  //     for (let i = 0; i < length; i++) {
  //       returnArray.push({
  //         x: i.toString(),
  //         y: Math.random() * (max - min) + min,
  //       });
  //     }

  //     return returnArray;
  //   }

  //   const data1 = generateData(100, 40, 50);

  const lineColorPalette = {
    red: {
      line: "#f87171",
      "gradient-start": "#7f1d1d",
      "gradient-end": "#ef4444",
    },

    green: {
      line: "#4ade80",
      "gradient-start": "#14532d",
      "gradient-end": "#10b981",
    },

    blue: {
      line: "#60a5fa",
      "gradient-start": "#1e3a8a",
      "gradient-end": "#3b82f6",
    },
  };

  const customTheme = buildChartTheme({
    backgroundColor: "#4B5563",
    colors: [lineColorPalette[props.color]["line"]],
    svgLabelBig: {
      fill: "#D1D5DB",
      fontFamily: "Inter",
      fontWeight: "500",
      letterSpacing: "-0.025em",
    },
    svgLabelSmall: {
      fill: "#9CA3AF",
      fontFamily: "Inter",
      fontWeight: "500",
      letterSpacing: "-0.025em",
    },
    htmlLabel: {
      fontFamily: "Inter",
      fontWeight: "500",
      boxShadow: "0 1px 2px 0 rgb(0 0 0 / 0.05)",
    },
    gridColor: "#9CA3AF",
    gridColorDark: "#9CA3AF",
  });

  let data = props.data ? props.data.map((value, key) => {
    return {
      x: key,
      y: value[1],
    };
  }) : [];

  console.log(data);


  return (
    <>
      <div className="flex flex-col w-full h-full p-8 overflow-auto">
        <h1 className="text-2xl text-slate-50 font-bold tracking-tight">
          {props.title}
        </h1>
        <div className="flex w-full h-full">
          <ParentSize>
            {({ width, height }) => (
              <XYChart
                theme={customTheme}
                width={width}
                height={height}
                xScale={{ type: "linear" }}
                yScale={{ type: "linear" }}
              >
                <AnimatedAxis label={props.xAxis} orientation={"bottom"} />
                <AnimatedAxis label={props.yAxis} orientation={"left"} />
                <AnimatedGrid rows={false} columns={false} />
                <LinearGradient
                  id="red"
                  from={lineColorPalette["red"]["gradient-end"]}
                  to={lineColorPalette["red"]["gradient-start"]}
                />
                <LinearGradient
                  id="green"
                  from={lineColorPalette["green"]["gradient-end"]}
                  to={lineColorPalette["green"]["gradient-start"]}
                />
                <LinearGradient
                  id="blue"
                  from={lineColorPalette["blue"]["gradient-end"]}
                  to={lineColorPalette["blue"]["gradient-start"]}
                />
                <AnimatedAreaSeries
                  dataKey="Line 1"
                  data={data}
                  fill={`url(#${props.color})`}
                  xAccessor={(d) => d?.x}
                  yAccessor={(d) => d?.y}
                  opacity={0.75}
                />
              </XYChart>
            )}
          </ParentSize>
        </div>
      </div>
    </>
  );
}
