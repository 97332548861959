import * as Mui from "@mui/material";
import React from "react";
import ReactJson from 'react-json-view'
import CircleIcon from '@mui/icons-material/Circle';
import { green, red } from '@mui/material/colors';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { IconButton } from "@mui/material";
import { newApiObj } from "../utils";
import uuid from "react-uuid";
import DeleteIcon from '@mui/icons-material/Delete';

export default function AddCardGrid(props) {
    const {json:card, updateCard, apiIntervals, initialData, setOpen, forceRefresh, apiRun} = props;

    let data = card.data;
    let apis = card.apis;
    let name = card.name;
    let id = card.id;
    let position = card.position;

    const [editData, setEditData] = React.useState({...initialData, ...data});
    const [editApi, setEditApi] = React.useState(apis);
    const [apijsx, setApiJsx] = React.useState([]);

    const updateApiEdit = React.useCallback((edit)=>{
        setEditApi(editApi.map((a)=> a.id === edit["updated_src"].id ? edit["updated_src"] : a));
    }, [editApi]);

    const deleteApi = React.useCallback((api)=>{
        setEditApi(editApi.filter((a)=> a.id !== api.id));
    });

    console.log("outside api intervals", apiIntervals);



    React.useEffect(()=>{
        let apijsx = [];
        if (editApi) {
            editApi.forEach((api)=>{
                console.log("apiintervals", apiIntervals);
                let apiInterval = apiIntervals[api.apiId];
                apijsx.push(<div key={api.id}><Mui.Typography variant="h6">
                    <CircleIcon sx={{ color: apiInterval ? green["A700"] : red["A700"] }} />{api.name}
                    <IconButton
                        aria-label="Run Once"
                        onClick={()=>{
                            apiRun(api);
                        }}
                        onMouseDown={(e) => {e.stopPropagation();}}
                    >
                        <PlayArrowIcon />
                    </IconButton>
                    <IconButton
                        aria-label="delete"
                        onClick={()=>{
                            deleteApi(api);
                        }}
                        onMouseDown={(e) => {e.stopPropagation();}}
                    >
                        <DeleteIcon />
                    </IconButton>
                </Mui.Typography>
                <ReactJson src={api} 
                onEdit={(edit)=>{updateApiEdit(edit)}}
                onDelete={(edit)=>{updateApiEdit(edit)}}
                onAdd={(edit)=>{updateApiEdit(edit)}}/>
                </div>
                );
            }
            );
        }
        setApiJsx(apijsx);
    }, [editApi, apiIntervals, apiRun, updateApiEdit]);

    const saveData = React.useCallback(()=>{
        let newData = {...card};
        newData.data = editData;
        updateCard(newData); 
        forceRefresh();
        setOpen(false);
        }, [editData, updateCard, forceRefresh, setOpen]);

    const addApi = React.useCallback(()=>{
        let id = uuid();
        let count = Object.keys(editApi).length;
        let newApi = newApiObj(id, count+1);
        setEditApi([...editApi, newApi]);
    }
    , [editApi, setEditApi]);

    const saveApi = React.useCallback(()=>{
        let newData = {...card};
        newData.apis = editApi;
        updateCard(newData);
        forceRefresh();
        setOpen(false);
    }
    , [editApi, updateCard, forceRefresh, setOpen]);
    
    return (
        <div style={{padding:'5%'}}>
        <Mui.Typography variant="h3" style={{textAlign:"left"}}>
            Default Data:
        </Mui.Typography>
        <ReactJson src={editData} onEdit={(edit)=>{setEditData(edit["updated_src"])}} onDelete={(edit)=>{setEditData(edit["updated_src"])}} onAdd={(edit)=>{setEditData(edit["updated_src"])}}/>
        <Mui.Button variant="contained" onClick={saveData}>
            Save Data
        </Mui.Button>
        <Mui.Box style={{height:"5%"}}>
            <Mui.Divider />
        </Mui.Box>
        <Mui.Typography variant="h3" style={{textAlign:"left"}}>
            APIs:
        </Mui.Typography>
        {apijsx}
        <Mui.Button variant="contained" onClick={addApi}>
            Add API +
        </Mui.Button>
        <Mui.Button variant="contained" onClick={saveApi}>
            Save APIs
        </Mui.Button>
        <Mui.Box style={{height:"5%"}}>
            <Mui.Divider />
        </Mui.Box>
        <Mui.Typography variant="h3" style={{textAlign:"left"}}>
            Info:
        </Mui.Typography>
        Name: {name}
        <br />
        ID: {id}
        <br />
        Position: {JSON.stringify(position)}
        </div>
    )
}