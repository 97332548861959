import * as React from 'react';
import Modal from '@mui/material/Modal';
import Card from '@mui/material/Card';

const style = {
  position: 'relative',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '75%',
  height: '75%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  textAlign: 'center',
};

export default function BasicModal(props) {
    const open = props.open;
    const setOpen = props.setOpen;
    const jsx = props.jsx;
    const additionalStyle = props.additionalStyle;
    const finalStyle = {...style, ...additionalStyle};
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        onMouseDown={(e) => {e.stopPropagation();}}
      >
        <Card style={finalStyle}>
            {jsx}
        </Card>
      </Modal>
    </div>
  );
}