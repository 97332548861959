import React from "react";
import { WidthProvider, Responsive } from "react-grid-layout";
import Card from "./Card";
import { AlignHorizontalLeft, AlignVerticalTop, Add } from "@mui/icons-material";
import { demoCard, getDemoCardData } from "../cards/Demo.jsx";
import { singleCard, getSingleCardData } from "../cards/Single.jsx";
import paragraphCard, { getParagraphCardData } from "../cards/Paragraph.jsx";
import { multifieldCard, getMultifieldCardData } from "../cards/Multifield.jsx";
import { getAddCardData, availableCards } from "../cards/AddCard.jsx";
import { webpageCard, getWebpageCardData } from "../cards/Webpage";
import GridSpeedDial from "./SpeedDial";
import { apiPath, getFromLS, saveToLS } from "../utils";
import Modal from "./Modal";
import uuid from "react-uuid";
import AddCardGrid from "./AddCard";
import _ from "lodash";
import { useDefaultTemplates } from "../hooks";
import axios from "axios";

const ResponsiveReactGridLayout = WidthProvider(Responsive);

export default function Grid(props) {
    // props
    let cards = props.cards;
    let setCards = props.setCards;
    let id = props.id;
    let forceUpdate = props.forceUpdate;
    let runUpdate = props.runUpdate;
    let apiIntervals = props.apiIntervals;
    let apiRun = props.apiRun;
    let fetchConfig = props.fetchConfig;
    let page = props.page - 1;
    let updateCards = props.updateCards;
    const isEditDisabled = props.isEditDisabled;

    // modal 
    const [modalOpen, setModalOpen] = React.useState(false);
    const [modalCard, setModalCard] = React.useState(null);

    // state
    const [stateLayout, setStateLayout] = React.useState(undefined);
    const [stateCompact, setStateCompact] = React.useState(null);

    // default templates
    const [defaultTemplates, setDefaultTemplates] = useDefaultTemplates();

    const [sharedBuffer, setSharedBuffer] = React.useState({});
    const [pastLayout, setPastLayout] = React.useState([]);

    const gridRef = React.useRef(null);

    // layouts
    React.useEffect(() => {
        let originalLayouts = {};
        //originalLayouts = getFromLS("layouts", id) || {};
        // console.log("SAVE from LS", originalLayouts);
        setStateLayout(originalLayouts);
    }, [id]);

    const resetLayout = () => {
        setStateLayout({});
    }

    const onLayoutChange = React.useCallback((layout, layouts) => {
        // if(stateCompact !== null) {
        //     console.log("COMPACTING");
        //     setTimeout(() => {
        //         setStateCompact(null);
        //         for (let i = 0; i < layout.length; i++) {
        //             console.log("COMPACTING", layout[i]);
        //             let item = layout[i];

        //             cards.find((card) => card.id === item.i).position = item;
        //         }
        //         updateCards(cards);
        //         console.log("DONE COMPACTING");
        //         setTimeout(() => {
        //             fetchConfig(id, page);
        //         }, 500);
        //     }, 1000);
        // }
    }, [stateCompact, cards]);

    const onDragStop = (layout) => {
        // let updatedCard = cards.find((card) => card.id === newitem.i);
        // updatedCard.position = newitem;
        // updateCard(updatedCard);
        for (let i = 0; i < layout.length; i++) {
            let item = layout[i];

            cards.find((card) => card.id === item.i).position = item;
        }
        updateCards(cards);
        setTimeout(() => {
            fetchConfig(id, page);
        }, 500);
    }

    const onResizeStop = (layout) => {
        for (let i = 0; i < layout.length; i++) {
            let item = layout[i];

            cards.find((card) => card.id === item.i).position = item;
        }
        updateCards(cards);
        setTimeout(() => {
            fetchConfig(id, page);
        }, 500);
    }

    const compact = (direction) => {
        console.log("compact", direction);
        if (direction === "horizontal") {
            setStateCompact("horizontal");
        } else if (direction === "vertical") {
            setStateCompact("vertical");
        } else {
            setStateCompact(null);
        }
    };

    // add or remove cards
    const removeCard = (cardId) => {
        axios.post(apiPath + "/dashboards/" + id + "/removecard", {
            "id": cardId
        }).then((res) => {
            console.log("Removed card", res);
            setCards(res.data.data.cards);
            fetchConfig(id, page);
        }).catch((err) => {
            console.log("Error removing card", err);
        });
        // let newCards = cards.filter((card) => card.id !== cardId);
        // setCards(newCards);
    }

    const addNewCard = (type, name) => {
        axios.post(apiPath + "/dashboards/" + id + "/addcard", {
            "name": "New " + name,
            "id": uuid(),
            "type": type,
            "position": { "w": 2, "h": 2, "x": 0, "y": 0, "minW": 1, "minH": 1 },
            "apis": [],
            "data": {}
        }).then((res) => {
            console.log("Added card", res);
            setCards(res.data.data.cards);
            setModalOpen(false);
            fetchConfig(id, page);
        }).catch((err) => {
            console.log("Error adding card", err);
        });
    }

    const updateCard = (card) => {
        // first remove then add card
        console.log("Updating card", card);
        axios.post(apiPath + "/dashboards/" + id + "/removecard", {
            "id": card.id
        }).then((res) => {
            console.log("Removed card", res);
            setCards(res.data.data.cards);
            axios.post(apiPath + "/dashboards/" + id + "/addcard", {
                "name": card.name,
                "id": card.id,
                "type": card.type,
                "position": card.position,
                "apis": card.apis,
                "data": card.data
            }).then((res) => {
                console.log("Updated card", res);
                setCards(res.data.data.cards);
                setModalOpen(false);
                fetchConfig(id, page);
            }).catch((err) => {
                console.log("Error updating card", err);
            });
        }).catch((err) => {
            console.log("Error removing card", err);
        });
    }

    // speed dial setup
    const speedDialActions = isEditDisabled ? [] : [
        // {
        //     name: "Compact Vertical",
        //     icon: <AlignVerticalTop/>,
        //     onClick: () => compact("vertical"),
        // },
        // {
        //     name: "Compact Horizontal",
        //     icon: <AlignHorizontalLeft/>,
        //     onClick: () => compact("horizontal"),
        // },
        {
            name: "Add Card",
            icon: <Add />,
            onClick: () => {
                let addCardData = getAddCardData(5);
                addCardData = { ...addCardData, ...availableCards };
                let modaljsx = (<AddCardGrid cards={defaultTemplates} customCards={{}} addNewCard={addNewCard} />);
                setModalCard(modaljsx);
                setModalOpen(true);
            },
        },
    ]

    // render cards
    let cardsToRender = [];
    if (cards) {
        console.log("Rendering cards...");
        cardsToRender = cards.map((card) => {
            if (defaultTemplates.length === 0) {
                return (<></>);
            }

            const template = defaultTemplates.find((item) => item._id === card.type);

            return (<div key={card.id} data-grid={card.position}>
                <Card jsx={template?.jsx} initialData={template?.initialData} removeCard={removeCard} json={card} updateCard={updateCard} runUpdate={runUpdate} apiIntervals={apiIntervals} apiRun={apiRun} sharedBuffer={sharedBuffer} setSharedBuffer={setSharedBuffer} isEditDisabled={isEditDisabled}/>
            </div>);
        }
        );
    }

    return (
      <div>
        <ResponsiveReactGridLayout
            className="layout bg-slate-900"
            breakpoints={{ xlg: 1500, lg: 1300, md: 996, sm: 768, xs: 480, xxs: 0}}
            cols={{ xlg: 14, lg:12, md: 10, sm: 8, xs: 6, xxs: 4 }}
            rowHeight={100}
            layouts={stateLayout}
            onLayoutChange={(layout, layouts) =>
                onLayoutChange(layout, layouts)
            }
            onDragStop={onDragStop}
            onResizeStop={onResizeStop}
            compactType={stateCompact}
            verticalCompact={true}
            ref={gridRef}
            isDraggable={!isEditDisabled}
            isDroppable={!isEditDisabled}
            isResizable={!isEditDisabled}
        >
          {cardsToRender}
        </ResponsiveReactGridLayout>
        <div className="fixed flex bottom-0 right-0 justify-center">
            <GridSpeedDial className="mb-4 mr-4 bg-slate-50" actions={speedDialActions}/>
        </div>
        <Modal open={modalOpen} setOpen={setModalOpen} jsx={modalCard} additionalStyle={{textAlign:"left"}}/>
      </div>
    );
}


