import React from 'react';
import AppBar from '../components/AppBar'
import Grid from '../components/Grid'
import { runAPI, parseAPI } from '../utils';
import { useConfig, useLoading, useDefaultApiList, useAPIData, useLogin } from '../hooks';

export default function Dashboard(props) {
    const {config, updateConfig, addPage, removePage, page, fetchConfig, createCopy} = props;

    const [name, setName] = React.useState(config.name ? config.name : 'Dashboard');
    const [cards, setCards] = React.useState(config.cards);
    const [runUpdate, setRunUpdate] = React.useState(false);
    const [apiIntervals, setApiIntervals] = React.useState([]);

    const [defaultApiList, setDefaultApiList] = useDefaultApiList();
    const [apiData, setApiData] = useAPIData();

    const [isLoading, setIsLoading] = useLoading();
    const [login, setLogin] = useLogin();

    const isLoginPage = (config.id === "fc3415-fd7b-b7a5-6240-08137157362e");

    const isViewOnly = (config.ownerid === null || config.ownerid !== login) && !isLoginPage;

    const [isEditDisabled, setIsEditDisabled] = React.useState(true);

    React.useEffect(() => {
        if (isLoginPage){
            setIsEditDisabled(false);
        }
    }, [config]);

    const changeEdit = () => {
        if (isEditDisabled){
            if(isViewOnly){
                // prompt user to either create a copy, or deny request to change to edit
                createCopy(config, "This is a view only dashboard. Would you like to create a copy?");
            }else{
                setIsEditDisabled(false);
            }
        }else{
            setIsEditDisabled(true);
        }
    }

    const copyToNew = () => {
        createCopy(config, "Would you like to create a copy?");
    }

    const id = config._id;

    const apiRun = React.useCallback((apiConfig)=>{
        let apiId = apiConfig.apiId;
        let apiTemplateId = apiConfig.templateId;
        let apiObj = defaultApiList.find((api)=>api._id === apiTemplateId);
        console.log("apiRun", apiObj, apiTemplateId, defaultApiList);

        if(apiObj !== undefined) {
            runAPI(apiObj, apiConfig).then((data)=>{
                let parsedData = parseAPI(data["data"], apiObj.data);
                apiData[apiId] = parsedData;
                console.log("ApiData", apiData);
                setApiData(apiData);
                setRunUpdate((update)=>!update);
            }).catch((err)=>{
                // fail silently
                //console.log(err);
            });
        }
    }, [defaultApiList, apiData, setApiData]);

    const clearApi = React.useCallback(()=>{
        Object.keys(apiIntervals).forEach((key)=>{
            clearInterval(apiIntervals[key]);
        });
    }, [apiIntervals]);

    const restartApi = React.useCallback(()=>{
        console.log("restarting APIs", config);
        clearApi(apiIntervals);

        // start new api intervals
        let newApiIntervals = {};
        config.cards.forEach((card)=>{
            if (card.apis) {
                // if apiId is already in apiIntervals, dont add it again
                card.apis.forEach((api)=>{
                    if (newApiIntervals[api.apiId] === undefined) {
                        // run api
                        apiRun(api, defaultApiList);
                    
                        if (api.interval > 0) {
                            newApiIntervals[api.apiId] = setInterval(()=>{
                                apiRun(api, defaultApiList);
                            }, api.interval);
                        }
                    }
                });
            }
        });

        setApiIntervals(newApiIntervals);
    }, [config, defaultApiList, apiIntervals, apiRun, clearApi, setRunUpdate, runUpdate]);

    const forceUpdate = () => {
        //setRunUpdate(!runUpdate);
        let newConfig = {...config, name: name, cards: cards};
        updateConfig(page-1, newConfig);
        restartApi();
        setRunUpdate(!runUpdate);
    }

    React.useEffect(() => {
        restartApi(defaultApiList, apiIntervals);
    },[defaultApiList, isLoading]);

    React.useEffect(() => {
        restartApi();
        setName(config.name ? config.name : 'Dashboard');
        setCards(config.cards);
    },[config]);

    const updateName = (name) => {
        let newConfig = {...config, name: name, cards: cards};
        console.log("updating config", page-1, newConfig);
        updateConfig(page-1, newConfig);
    }

    const updateCards = (cards) => {
        setCards(cards);
        let newConfig = {...config, name: name, cards: cards};
        updateConfig(page-1, newConfig);
    }

    // React.useEffect(() => {
    //     let newConfig = {...config, name: name, cards: cards};
    //     //updateConfig(page-1, newConfig);
    //     console.log("updating config", page-1, newConfig);
    //     restartApi(defaultApiList, apiIntervals);
    // },[config, name, cards]);

    return (
        <div style={{"height": '100%'}}>
            <AppBar name={name} setName={updateName} addPage={addPage} removePage={removePage} pageid={id} isEditDisabled={isEditDisabled} changeEdit={changeEdit} copyToNew={copyToNew} isLoginPage={isLoginPage}/>
            <Grid cards={cards} setCards={setCards} id={id} forceUpdate={forceUpdate} key={id} runUpdate={runUpdate} apiIntervals={apiIntervals} apiRun={apiRun} fetchConfig={fetchConfig} page={page} updateCards={updateCards} isEditDisabled={isEditDisabled} />
        </div>
    )
}