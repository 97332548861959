import { useContext } from "react";
import { Contexts } from "./contexts";

export const useLoading = () => {
    const { isLoading, setIsLoading } = useContext(Contexts);
    return [isLoading, setIsLoading];
};

export const useConfig = () => {
    const { config, setConfig } = useContext(Contexts);
    return [config, setConfig];
};

export const useDefaultApiList = () => {
    const { defaultApiList, setDefaultApiList } = useContext(Contexts);
    return [defaultApiList, setDefaultApiList];
};

export const useAPIData = () => {
    const { apiData, setApiData } = useContext(Contexts);
    return [apiData, setApiData];
};

export const useDefaultTemplates = () => {
    const { defaultTemplates, setDefaultTemplates } = useContext(Contexts);
    return [defaultTemplates, setDefaultTemplates];
}

export const useLogin = () => {
    const { login, setLogin } = useContext(Contexts);
    return [login, setLogin];
}