import React from "react";
import { useLoading, useConfig, useDefaultTemplates, useDefaultApiList } from "../hooks";
import axios from "axios";
import { useCookies } from 'react-cookie';
import { apiPath } from "../utils";
import loginConfig from '../loginConfig.json';
import { useLogin } from "../hooks";

const GetAllDB = (props) => {
    const [loading, setIsLoading] = useLoading();
    const [, setConfig] = useConfig();
    const [defaultTemplates, setDefaultTemplates] = useDefaultTemplates();
    const [, setDefaultApiList] = useDefaultApiList();
    const [cookies, setCookie, removeCookie] = useCookies(['login']);
    const [login, setLogin] = useLogin();

    React.useEffect(() => {
        setIsLoading(true);
        if (cookies.username) {
            axios.post(apiPath + "/users/login", {username: cookies.username, password: cookies.password}).then((res) => {
                setIsLoading(true);
                console.log("Login", res);
                if (res.status === 200) {
                    console.log("Login success");
                    // run axios get with data of array res.data.data.dashboards
                    console.log("dashboards", res.data.data.dashboards);
                    setLogin(res.data.data._id);
                    let apilink = apiPath + "/dashboards?dashboardids="
                    let dashboards = [];
                    for(let i = 0; i < res.data.data.dashboards.length; i++){
                        console.log("dashboards", res.data.data.dashboards[i].id);
                        dashboards.push(res.data.data.dashboards[i].id);
                    }
                    apilink += JSON.stringify(dashboards);
                    console.log("apilink", apilink);
                    axios.get(apilink).then((res) => {
                        console.log("get dashboards", res.data.data);
                        setConfig(res.data.data);
                    }).catch((err) => {
                        console.log("Get Dashboards Failed", err);
                    });
                }else{
                    alert("Login failed, please try again");
                    removeCookie("username");
                    removeCookie("password");
                    
                    setTimeout(() => {
                    window.location.reload();
                    }, 1000);
                }
                setIsLoading(false);
            }).catch((err) => {
                console.log("Error logging in", err);
                alert("Login failed, please try again. " + err?.response?.data?.message);
                removeCookie("username");
                removeCookie("password");
                setTimeout(() => {
                    window.location.reload();
                    }, 1000);
                setIsLoading(false);
            });
        }else{
            setConfig(loginConfig);
        }

        axios.get(apiPath + "/templates").then((res) => {
            setIsLoading(true);
            console.log("get templates", res.data.data);
            setDefaultTemplates(res.data.data);
            setIsLoading(false);
        }).catch((err) => {
            console.log("Get Templates Failed", err);
            setIsLoading(false);
        });

        axios.get(apiPath + "/apis").then((res) => {
            setIsLoading(true);
            console.log("get apis", res.data.data);
            setDefaultApiList(res.data.data);
            setIsLoading(false);
        }).catch((err) => {
            console.log("Get API Failed", err);
            setIsLoading(false);
        });
    }, []);

    React.useEffect(() => {
        console.log("GetAllDB2", defaultTemplates);
    }, [defaultTemplates]);

    React.useEffect(() => {
        console.log("loading", loading);
    }, [loading]);

    return null;
};

export default GetAllDB;
