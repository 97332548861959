import * as React from 'react';
import * as Mui from '@mui/material';
import Card from '@mui/material/Card';
import JsxParser from 'react-jsx-parser';
import { Textfit } from 'react-textfit';
import Ellipsis from '../components/Ellipsis';
import Modal from '../components/Modal';
import Iframe from 'react-iframe';
import { runAPI, parseAPI, logIn } from '../utils';
import EditCard from './EditCard';
import _ from 'lodash';
import { useConfig, useLoading, useApiList, useAPIData } from '../hooks';
import { useCookies } from 'react-cookie';
import VisxXYGraphCard from '../cards/VisxXYGraphCard';
import GlanceCard from '../cards/GlanceCard';

export default function BasicCard(props) {
    const { json: card, updateData, removeCard, updateCard, runUpdate, initialData, jsx: jsxstring, isEditDisabled } = props;
    const id = card.id ? card.id : null;
    const apis = card.apis;
    const data = card.data;
    const apiIntervals = props.apiIntervals;
    const apiRun = props.apiRun;
    const sharedBuffer = props.sharedBuffer;
    const setSharedBuffer = props.setSharedBuffer;

    const [cookies, setCookie] = useCookies(['login']);

    const bufferData = {
        usernameAddToBuffer: (username) => { setSharedBuffer((sharedBuffer) => { return { ...sharedBuffer, username: username.target.value } }); },
        passwordAddToBuffer: (password) => { setSharedBuffer((sharedBuffer) => { return { ...sharedBuffer, password: password.target.value } }) },
        submitButton: (event) => { event.preventDefault(); setCookie('username', sharedBuffer.username, { path: '/' }); setCookie('password', sharedBuffer.password, { path: '/' }); window.location.reload(); },
    };


    const [finalData, setFinalData] = React.useState({ ...initialData, ...data });

    const [refresh, setRefresh] = React.useState(false);
    const [apiData, setApiData] = useAPIData();

    // for modal
    const [open, setOpen] = React.useState(false);
    const [jsx, setJsx] = React.useState(null);

    // for ellipses
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [additionalStyle, setAdditionalStyle] = React.useState({});

    let cardjsx = (<JsxParser className="flex w-full h-full" components={{ Mui, Textfit, Iframe }} bindings={finalData} jsx={jsxstring} />);
    if (card.type === "UsernameLogin") {
        cardjsx = (<Mui.Grid style={{ height: "100%" }} component={Mui.Stack} direction="column" justifyContent="center">
            <Mui.CardContent>
                <Mui.Typography>
                    <Mui.TextField label="Username" variant="outlined" onChange={bufferData.usernameAddToBuffer} fullWidth type="text" />
                </Mui.Typography>
            </Mui.CardContent></Mui.Grid>);
    } else if (card.type === "PasswordLogin") {
        cardjsx = (<Mui.Grid style={{ height: "100%" }} component={Mui.Stack} direction="column" justifyContent="center">
            <Mui.CardContent>
                <Mui.Typography>
                    <Mui.TextField label="Password" variant="outlined" onChange={bufferData.passwordAddToBuffer} fullWidth type="password" />
                </Mui.Typography>
            </Mui.CardContent></Mui.Grid>);
    } else if (card.type === "SubmitFormLogin") {
        cardjsx = (<Mui.Grid style={{ height: "100%" }} component={Mui.Stack} direction="column" justifyContent="center">
            <Mui.CardContent>
                <Mui.Typography>
                    <Mui.Button variant="outlined" onClick={bufferData.submitButton} fullWidth>Submit</Mui.Button>
                </Mui.Typography>
            </Mui.CardContent></Mui.Grid>);
    } else if (card.type === "639971c77ea4e94a5b8a736c") { // XY Chart
        let currentData = { ...initialData, ...data, ...finalData };
        cardjsx = (
            <VisxXYGraphCard title={currentData.title} color={currentData.color} data={currentData.data} xAxis={currentData.xAxis} yAxis={currentData.yAxis} />
        );
    } else if (card.type === "639982b27ea4e94a5b8a736e") {
        let currentData = { ...initialData, ...data, ...finalData };
        cardjsx = (
            <GlanceCard title={currentData.title} color={currentData.color} value={currentData.value} />
        );
    }

    const forceRefresh = () => {
        setRefresh(!refresh);
    }

    const combineApiData = React.useCallback((apiData) => {
        let combinedData = {};
        if (apiData) {
            apis.forEach((api) => {
                if (apiData[api.apiId]) {
                    let obj = {};
                    obj[api.field] = apiData[api.apiId][api.var];
                    combinedData = _.merge(combinedData, obj);
                }
            });
        }
        return combinedData;
    }, [apis]);

    React.useEffect(() => {
        let combinedData = combineApiData(apiData);
        console.log("combinedData: ", combinedData);
        setFinalData((finalData) => { return { ...finalData, ...combinedData } });
    }, [combineApiData, apiData, runUpdate, refresh]);

    const ellipsisOptions = [
        {
            name: 'Edit', onClick: () => {
                setJsx(<EditCard updateCard={updateCard} json={card} apiIntervals={apiIntervals} initialData={initialData} setOpen={setOpen} forceRefresh={forceRefresh} apiRun={apiRun} />);
                setAdditionalStyle({ textAlign: 'left', overflow: 'scroll' });
                setOpen(true);
                setAnchorEl(null);
            }
        },
        {
            name: 'Delete', onClick: () => {
                removeCard(id);
                setAnchorEl(null);
            }
        },
        {
            name: 'Open', onClick: () => {
                setJsx(cardjsx);
                setAdditionalStyle({});
                setOpen(true);
                setAnchorEl(null);
            }
        },
        {
            name: 'Refresh', onClick: () => {
                forceRefresh();
                setAnchorEl(null);
            }
        },
    ]

    return (
        <>
            <div className="flex flex-column w-ful h-full bg-slate-800 rounded-xl">
                <div style={{ position: 'absolute', right: '5px', top: '5px' }}>
                {isEditDisabled ? "" : <Ellipsis options={ellipsisOptions} anchorEl={anchorEl} setAnchorEl={setAnchorEl} />}
                </div>
                {cardjsx}
            </div>
            <Modal jsx={jsx} open={open} setOpen={setOpen} additionalStyle={additionalStyle} />
        </>
    );
}
