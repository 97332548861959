import * as Mui from "@mui/material";

export default function AddCardGrid(props) {
    let cards = props.cards;
    let addNewCard = props.addNewCard;
    let customCards = props.customCards;
    cards = {...cards, ...customCards};

    let jsx = [];

    for (let key in cards) {
        let card = cards[key];
        jsx.push(<Mui.Grid item xs={1} sm={1} md={1} key={card.id} >
            <Mui.Paper style={{margin:"5%"}} onClick={()=>{addNewCard(card._id, card.name)}}>
                <Mui.CardContent>
                    <Mui.Typography variant="h5">
                        {card.name}
                    </Mui.Typography>
                    <Mui.Typography variant="body2" color="text.secondary" style={{height:"20%"}}>
                    {card.description}
                    </Mui.Typography>
                </Mui.CardContent>
            </Mui.Paper>
          </Mui.Grid>);
    }

    return (
        <Mui.Grid container columns={{ xs: 1, sm: 2, md: 3, lg: 4 }}>
            {jsx}
        </Mui.Grid>
    )
}